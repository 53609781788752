<template>
    <v-row>
      <v-col cols="12" style="padding:0px !important;">
        <v-carousel
              class="fill-height hidden-sm-and-down"
              :show-arrows="false"
              hide-delimiters
              interval="12000"
              height="500"
          >
              <v-carousel-item
              v-for="(item, i) in slides"
              :key="i"
              :src="item.src"
              reverse-transition="fade-transition"
              transition="fade-transition"
              >
              <div class="overlay">
                  <v-row class="fill-height" style="padding-top:150px;">
                  <v-col cols="11" offset="1" style="color:#ffffff;">
                      <v-layout wrap class="text-h4" style="color:#ffffff;">
                      <b>{{ item.title }}</b>
                      </v-layout>
                      <p class="ma-5" style="font-size: 18px;">
                      {{ item.text }}
                      </p>
                      <v-layout wrap>
                      <v-btn rounded color="#72bd61" dark>{{ item.btnText }}</v-btn>
                      </v-layout>
                  </v-col>
                  </v-row>
              </div>
              </v-carousel-item>
          
        </v-carousel>
        <v-carousel
              class="hidden-md-and-up"
              cycle
              :show-arrows="false"
              hide-delimiters
              height="200px"
              interval="10000"
          >
              <v-carousel-item
              v-for="(item, i) in slides"
              :key="i"
              :src="item.src"
              transition="fade"
              reverse-transition="fade"
              >
              <div class="overlay">
                  <v-row class="fill-height pt-5">
                  <v-col cols="8" offset="1" style="color:#ffffff;">
                      <v-layout wrap class="text-h6" style="color:#ffffff;">
                      {{ item.title }}
                      </v-layout>
                      <p>
                      {{ item.text }}
                      </p>
                      <v-layout wrap>
                      <v-btn rounded color="#72bd61" dark small>{{
                          item.btnText
                      }}</v-btn>
                      </v-layout>
                  </v-col>
                  </v-row>
              </div>
              </v-carousel-item>
        </v-carousel>
  
        <v-container fluid >
          <v-layout wrap>
                <v-col cols="12" align="center">
                  <h2><b>qikPharma Refund Policy</b></h2>
                </v-col>
                <v-col cols="12">
                    <p>
                        You have the right to cancel your order for medication up to the point when your medication is dispatched 
                        - this is defined as when you receive an email confirming that your order has been dispatched. 
                    </p>

                    <p>Should you be unable to collect your missed delivery or arrange for your medication to be redelivered, we can resend your medication to the same, or a different address provided it has been returned to us within 24 hours of the attempted delivery. </p>

                    <p>
                        Unfortunately, we cannot accept returns for medicines, including prescription medicines or anything with a broken hygiene seal. You have a right to inspect their items reasonably, but you can only return used items if they are damaged or faulty. 
                    </p>

                    <p>
                        In the unlikely event that you are not completely satisfied with any items purchased from us, details of our returns policy are below. 
                    </p>

                    <p>
                        To process a refund request, you must inform us of your return, and we must receive all items you wish to return within 24 hours from the date you purchased or received your product. 
                    </p>

                    <p>
                        Items must be in the original condition, which includes keeping any hygiene seals in place. We cannot accept a return for any items with a broken seal where this needs to be in place for health or hygiene reasons. 
                    </p>

                    <h4><b>How to return items </b></h4>
                    <p>
                        You can arrange a collection through qikPharma within 24 hours of purchase. We cannot process refunds for any items returned to collected at a pharmacy. 
                    </p>
                    <p>
                        We can only process a refund where a valid receipt exists with the card used to make the payment where applicable. We reserve the right to refuse refund requests 
                        where a valid receipt is not provided or offer an exchange or credit voucher. 
                    </p>
                    

                    <h4><b>Damaged and faulty items </b></h4>
                    <p>
                        If you believe any ordered items are damaged or faulty, you should contact the pharmacy or qikPharma as soon as possible. 
                    </p>
                    <p>
                        You can claim a refund on faulty goods within 24hours of receipt. In some cases, we may offer to replace or repair the item free of charge. 
                    </p>

                    <h4><b>Services <br/> Cancellations and Changing Appointments </b></h4>
                    <p>
                        When you book an appointment with us, we reserve the consultation room, and we cannot take any other services within this time slot. However, 
                        we accept that there might be genuine reasons why you need to change an appointment. 
                    </p>
                    <p>
                        In recognition of this, we will allow changes to an appointment a maximum of two times without any penalty. After this, we will not change any appointments and should you not attend; we will not issue a refund. We will consider all cancelled appointments from the time of booking when deciding if a refund is appropriate. 
                        We cannot change any appointments within 24 hours of the appointment start time. Once you make a change to a booking, we will no longer be able to cancel the booking. 
                    </p>

                    <h3><b>Our process for dealing with cancellations is detailed below. </b></h3>

                    <h3>Cancellations with more than 48 hours notice </h3>
                    <p>We will issue a full refund to the original payment method for appointments cancelled with more than 48 hours’ notice before the appointment starts. </p>

                    <h3><b>Cancellations with at least 24 hours notice </b></h3>
                    <p>
                        For services cancelled between 48 hours and 24 hours before the start of the appointment, we will issue a refund in the form of a credit voucher. The credit vouchers will be valid for six 
                        months from the date of issue and are only suitable for use on our website. 
                    </p>

                    <h3><b>Cancellations with less than 24 hours notice</b></h3>
                    <p>
                        We cannot issue refunds for services cancelled within 24 hours of or after the appointment start time. 
                    </p>
                      

                    <h3><b>Cancellations for travel services </b></h3>
                    <p>
                        We will not issue a refund for travel services once we have conducted a consultation. 
                    </p>
                    <p>
                        We will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period, except when this data is used to strengthen the security or to improve the functionality of our Service, 
                        or we are legally obligated to retain this data for longer time periods.
                    </p>

                    <h3><b>Liability </b></h3>
                    <p>
                        Our maximum liability for our failure to fulfil an order or service that we are legally bound to fulfil will be limited to your price for that order or service 
                    </p>
                    

                </v-col>
          </v-layout>
  
          <v-layout wrap>
              <v-col cols="12" md="4" offset-md="2" align="left">
                      <v-icon
                          style="color:#7CBF46 !important; font-size:70px !important"
                          dark
                          >mdi-basket-fill
                      </v-icon> <span style="font-size:25px !important"><b>Product Quality</b></span>
              </v-col>
               <v-col cols="12" md="5" offset-md="1" align="left">
                      <v-icon
                          style="color:#7CBF46 !important; font-size:70px !important"
                          dark
                          >mdi-timer-check
                      </v-icon> <span style="font-size:25px !important"><b>On-Time Delievery</b></span>
              </v-col>
          </v-layout>
  
  
  
        </v-container>
  
      
      </v-col>
    </v-row>
  </template>
  
  <script>
  export default {
    name: "RefundPolicy",
  
    data: () => ({
        slides: [
          {
            src: "slider/slider2.jpg",
            title: "Refund Policy",
            text: "Protect Your qikPharma Orders.",
            btnText: "Learn How it Works",
            link: "#",
          },
        ],
    })
  };
  </script>
  
  <style scoped>
  .overlay {
    background-color: rgba(0, 0, 0, 0.6);
    height: 100%;
  }
  
  .box1 {
    background: linear-gradient(192.88deg, #111212 43.53%, #72bd61 90.69%);
    border-radius: 10px;
    color: #ffffff;
  }
  
  .box2 {
    background: rgba(0, 0, 0, 0.07);
    border-radius: 10px;
    color: #171a17;
  }
  
  .barcodeTitle {
    color: #000000;
    font-size: 16px;
    text-align: center;
  }
  
  .barcodeText {
    color: #c2c2c2;
    font-size: 12px;
    margin-top: 15px;
    text-align: center;
  }
  
  .centerImg {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100px;
  }
  
  p {
    line-height: 1.6;
  }
  
  li {
    line-height: 1.6;
  }
  
  </style>
  